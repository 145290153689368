/**
 * A custom jQuery UI widget that implements a simple combobox. Uses a <select> element along with jQuery UI's autocomplete.
 * Adapted from https://jqueryui.com/autocomplete/#combobox.
 **/

// define custom jquery widget named 'combobox'
$.widget('custom.combobox', {
  _create: function() {
    this.element.hide();
    this._createAutocomplete();
  },

  // init function: create the input element and bind input events
  _createAutocomplete: function() {
    const selected = this.element.children(':selected'),
      value = selected.val() ? selected.text() : '';
    this.input = $('<input>')
      .insertBefore(this.element)
      .attr('placeholder', 'Start typing to search.')
      .addClass('form-control')
      .val(value)
      .autocomplete({
        delay: 0,
        minLength: 0,
        source: $.proxy(this, '_source')
      });

    // bind events on input element:
    this._on(this.input, {
      // trigger a search without a search term (which returns all items) on focus
      focus: function(event) {
        this.input.autocomplete('search', null);
      },
      
      // trigger an autocompletechange event on selecting an option
      autocompleteselect: function(event, ui) {
        this.input.val(ui.item.value);  // update the input's contents
        this.input.trigger('autocompletechange', event, ui);
      },

      // remove search term if it doesn't match any options
      autocompletechange: function(event, ui) {
        // search for a full match (case-insensitive)
        let value = this.input.val(),
          valid = false;
        this.element.children('option').each(function() {
          if ($(this).text().toLowerCase() === value.toLowerCase()) {
            // if found a match, select it and set valid to true
            this.selected = valid = true;
            return false;
          }
        });
        // if no match found, remove search term
        if (!valid) {
          this.input.val('');
          this.element.val('');
          this.input.autocomplete("instance").term = '';
        }
        // trigger a 'combobox.change' event
        this._trigger('.change', event, {
          search: value,
          match: valid
        });
      }
    });
  },

  // use select element's options as autocomplete source
  _source: function(request, response) {
    const matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), 'i');
    response(this.element.children('option').map(function() {
      const text = $(this).text();
      const search_against = text + ' (' +  $(this).attr('data-address1') + ' ' + $(this).attr('data-address2') + ')';
      if (this.value && (!request.term || matcher.test(search_against))) {
        return {label: search_against, value: text, option: this}
      }
    }));
  },

  // when destroying the combobox, revert to plain select
  _destroy: function() {
    this.input.remove();
    this.element.show();
  }
});
