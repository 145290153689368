
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

// FormBuilder node module
// we are loading form-builder.min.js 2.9.8 here using webpack,
// but form-render.min.js 2.10.0 are placed in the head.blade.php files for both home and admin
// form-render.min.js 2.10.0 can load userData back into the form for data editing
// but when this file is included here it throws an "unexpected token" error
// the files are now placed under the public/vendor/formbuilder folder
// formBuilder 2.9.8 -> formbuilder.js 2.10.0
// Apr 24, 2019 PC: upgraded formBuilder to 3.2.1
// May 17, 2019 TL: upgraded formBuilder to 3.2.3
//  also upgraded laravel-mix to 4.0.15, which also seems to have fixed the "unexpected token" issue
window.formBuilder = require('formBuilder');
window.formRender = require('formBuilder/dist/form-render.min.js');

/**
 * Loading jQuery & co.
 */
import $ from 'jquery';
window.$ = window.jQuery = $;
// jQuery UI
import 'jquery-ui-dist/jquery-ui.js';
// daterangepicker bundles it's own moment.js but it throws error when Webpack compiles
import 'moment/moment.js';
// daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.js';
// jQuery UI for datepicker & combobox
import 'jquery-ui/ui/widgets/datepicker.js';
require('./jquery-ui/combobox');

// Summernote
import 'summernote/src/js/bs4/settings.js';
$(function() {
    // default keymap (copied from node_modules/summernote/src/js/base/settings.js)
    //  minus tab/shift+tab
    let keyMap = {
      pc: {
        'ENTER': 'insertParagraph',
        'CTRL+Z': 'undo',
        'CTRL+Y': 'redo',
        // 'TAB': 'tab',
        // 'SHIFT+TAB': 'untab',
        'CTRL+B': 'bold',
        'CTRL+I': 'italic',
        'CTRL+U': 'underline',
        'CTRL+SHIFT+S': 'strikethrough',
        'CTRL+BACKSLASH': 'removeFormat',
        'CTRL+SHIFT+L': 'justifyLeft',
        'CTRL+SHIFT+E': 'justifyCenter',
        'CTRL+SHIFT+R': 'justifyRight',
        'CTRL+SHIFT+J': 'justifyFull',
        'CTRL+SHIFT+NUM7': 'insertUnorderedList',
        'CTRL+SHIFT+NUM8': 'insertOrderedList',
        'CTRL+LEFTBRACKET': 'outdent',
        'CTRL+RIGHTBRACKET': 'indent',
        'CTRL+NUM0': 'formatPara',
        'CTRL+NUM1': 'formatH1',
        'CTRL+NUM2': 'formatH2',
        'CTRL+NUM3': 'formatH3',
        'CTRL+NUM4': 'formatH4',
        'CTRL+NUM5': 'formatH5',
        'CTRL+NUM6': 'formatH6',
        'CTRL+ENTER': 'insertHorizontalRule',
        'CTRL+K': 'linkDialog.show',
      }, mac: {
        'ENTER': 'insertParagraph',
        'CMD+Z': 'undo',
        'CMD+SHIFT+Z': 'redo',
        // 'TAB': 'tab',
        // 'SHIFT+TAB': 'untab',
        'CMD+B': 'bold',
        'CMD+I': 'italic',
        'CMD+U': 'underline',
        'CMD+SHIFT+S': 'strikethrough',
        'CMD+BACKSLASH': 'removeFormat',
        'CMD+SHIFT+L': 'justifyLeft',
        'CMD+SHIFT+E': 'justifyCenter',
        'CMD+SHIFT+R': 'justifyRight',
        'CMD+SHIFT+J': 'justifyFull',
        'CMD+SHIFT+NUM7': 'insertUnorderedList',
        'CMD+SHIFT+NUM8': 'insertOrderedList',
        'CMD+LEFTBRACKET': 'outdent',
        'CMD+RIGHTBRACKET': 'indent',
        'CMD+NUM0': 'formatPara',
        'CMD+NUM1': 'formatH1',
        'CMD+NUM2': 'formatH2',
        'CMD+NUM3': 'formatH3',
        'CMD+NUM4': 'formatH4',
        'CMD+NUM5': 'formatH5',
        'CMD+NUM6': 'formatH6',
        'CMD+ENTER': 'insertHorizontalRule',
        'CMD+K': 'linkDialog.show',
      },
    }

    $('.summernote').summernote({
        callbacks: {
          // disable drag+dropping anything into summernote
          onInit: function(e) {
            $('.note-editable').on('drop', function(e) {
              return false;
            });
          },
          // disallow image 'upload' (i.e. pasting)
          onImageUpload: function(data) {
            return false;
          }
        },
        disableDragAndDrop: true,
        placeholder: 'Start writing!',
        height: 200,
        keyMap: keyMap,
    });
});

// Isotope
require('isotope-layout/dist/isotope.pkgd.min');

// Font Awesome 5
import fontawesome from '@fortawesome/fontawesome'
// importing all free libraries adds to app.js size - need to explore treeshaking
import solid from '@fortawesome/fontawesome-free-solid'
import regular from '@fortawesome/fontawesome-free-regular'
import brands from '@fortawesome/fontawesome-free-brands'
fontawesome.library.add(solid, regular, brands)

// Remove empty fragments (i.e. "#" url suffix)
$(window).on('load', function (e) {
  if (window.location.hash == "") {
    history.pushState("", document.title, window.location.pathname + window.location.search)
  }
})